<template>
  <div class="card-padding">
    <v-form ref="form" lazy-validation @submit.prevent="searchDeviceData()">
      <v-row class="pb-1">
        <v-col class="col-6 col-lg-3 py-0 pr-0 d-flex justify-space-between">
          <v-select
            v-model="typeConsumo"
            :items="items"
            item-text="value"
            item-value="id"
            dense
            clas="py-0"
            label="Tipo consumo"
            :rules="[(v) => !!v || 'El  tipo de filtro es obligatorio']"
            outlined
          ></v-select
        ></v-col>
        <!-- date start -->
        <v-col class="col-6 col-lg-3 py-0 pr-0 pd-flex justify-space-between">
          <v-dialog
            ref="dialogStart"
            v-model="modalDateRangeStart"
            :return-value.sync="dateStart"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateStart"
                append-icon="mdi-calendar-start"
                class="flex-grow-1 mr-md-2"
                label="Fecha inicio"
                outlined
                readonly
                dense
                clearable
                placeholder="Fecha inicio"
                v-bind="attrs"
                :rules="[(v) => !!v || 'Fecha de inicio es obligatorio']"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateStart"
              scrollable
              :max="maxDateStart"
              locale="es-pe"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modalDateRangeStart = false"
                >Cancelar</v-btn
              >
              <v-btn text color="primary" @click="saveDateStart()">OK</v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <!-- date end -->
        <v-col class="col-6 col-lg-3 py-0 pr-0 d-flex justify-space-between">
          <v-dialog
            ref="dialogEnd"
            v-model="modalDateRangeEnd"
            :return-value.sync="dateEnd"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateEnd"
                append-icon="mdi-calendar-end"
                class="flex-grow-1 mr-md-2"
                label="Fecha fin"
                readonly
                outlined
                dense
                clearable
                placeholder="Fecha fin"
                v-bind="attrs"
                :rules="[(v) => !!v || 'Fecha fin es obligatorio']"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateEnd"
              scrollable
              :min="minDateEnd"
              locale="es-pe"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modalDateRangeEnd = false"
                >Cancelar</v-btn
              >
              <v-btn text color="primary" @click="saveDateEnd()">OK</v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <!-- btn search -->
        <v-col class="col-12 col-lg-2 py-0 d-flex justify-space-between">
          <v-btn class="flex-grow-1" color="secundary" type="submit">
            Buscar
          </v-btn>

          <!-- <v-row> -->
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn
            v-if="isAdmin"
            :loading="isLoadingDownloadPdf"
            class="ml-5 mr-2"
            color="primary"
            dark
            @click="downloadPdf()"
          >
            <svg
              width="17px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
            >
              <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
              <path
                d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM64 224H88c30.9 0 56 25.1 56 56s-25.1 56-56 56H80v32c0 8.8-7.2 16-16 16s-16-7.2-16-16V320 240c0-8.8 7.2-16 16-16zm24 80c13.3 0 24-10.7 24-24s-10.7-24-24-24H80v48h8zm72-64c0-8.8 7.2-16 16-16h24c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H176c-8.8 0-16-7.2-16-16V240zm32 112h8c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16h-8v96zm96-128h48c8.8 0 16 7.2 16 16s-7.2 16-16 16H304v32h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H304v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V304 240c0-8.8 7.2-16 16-16z"
              />
            </svg>
          </v-btn>
          <v-btn
            v-if="isAdmin"
            :loading="isLoadingDownload"
            class="mr-2"
            color="primary"
            dark
            @click="downloadExcel()"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <title>microsoft-excel</title>
              <path
                d="M21.17 3.25Q21.5 3.25 21.76 3.5 22 3.74 22 4.08V19.92Q22 20.26 21.76 20.5 21.5 20.75 21.17 20.75H7.83Q7.5 20.75 7.24 20.5 7 20.26 7 19.92V17H2.83Q2.5 17 2.24 16.76 2 16.5 2 16.17V7.83Q2 7.5 2.24 7.24 2.5 7 2.83 7H7V4.08Q7 3.74 7.24 3.5 7.5 3.25 7.83 3.25M7 13.06L8.18 15.28H9.97L8 12.06L9.93 8.89H8.22L7.13 10.9L7.09 10.96L7.06 11.03Q6.8 10.5 6.5 9.96 6.25 9.43 5.97 8.89H4.16L6.05 12.08L4 15.28H5.78M13.88 19.5V17H8.25V19.5M13.88 15.75V12.63H12V15.75M13.88 11.38V8.25H12V11.38M13.88 7V4.5H8.25V7M20.75 19.5V17H15.13V19.5M20.75 15.75V12.63H15.13V15.75M20.75 11.38V8.25H15.13V11.38M20.75 7V4.5H15.13V7Z"
              />
            </svg>
          </v-btn>
          <!-- </v-row> -->
        </v-col>
      </v-row>
    </v-form>

    <div class="chart mt-8">
      <v-progress-linear
        v-if="isloading"
        indeterminate
        color="yellow darken-2"
      />

      <div v-else>
        <apexchart
          v-if="typeConsumo === 1"
          height="300"
          type="line"
          :options="chartOptions"
          :series="seriesApex"
        ></apexchart>

        <apexchart
          v-else
          height="300"
          type="bar"
          :options="chartOptions"
          :series="seriesApex"
        ></apexchart>
      </div>
    </div>
  </div>
</template>
<script>
import BackendApi from "@/services/backend.service";
import moment from "moment-timezone";

import xlsx from "json-as-xlsx";

import jspdf from "jspdf";
import html2canvas from "html2canvas";

moment().tz("America/Lima").format();

export default {
  name: "line-chart",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    idDeviceString: {
      type: String,
      default: () => "",
    },
    deviceDataId: {
      type: Number,
    },
    service: {
      type: String,
    },
  },
  data: function () {
    return {
      isloading: false,
      typeConsumo: 1,
      seriesBar: [
        {
          name: "Caudal",
          data: [],
        },
      ],
      seriesArea: [
        {
          name: "Consumo",
          data: [],
        },
      ],
      isLoadingDownloadPdf: false,
      isLoadingDownload: false,
      lineChartId: "line-chart",
      type: "line",
      data: {},
      maxDateStart: null,
      dateStart: null,
      minDateEnd: null,
      dateEnd: null,
      timeInicio: 0,
      timeFinal: 0,
      modalDateRangeStart: null,
      modalDateRangeEnd: null,
      tendenciaVolumen: null,
      tendenciaCaudal: null,
      acumuladoMensual: 0,
      unidad: "",
      chartOptions: {},
      seriesApex: [],
    };
  },
  computed: {
    chartOptionsBar() {
      return {
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: true,
            export: {
              png: {
                filename:
                  this.idDeviceString +
                  " - " +
                  moment(this.dateStart).format("DD/MM/YYYY") +
                  " - " +
                  moment(this.dateEnd).format("DD/MM/YYYY"),
              },
              svg: {
                filename:
                  this.idDeviceString +
                  " - " +
                  moment(this.dateStart).format("DD/MM/YYYY") +
                  " - " +
                  moment(this.dateEnd).format("DD/MM/YYYY"),
              },
            },
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top",
            },
          },
        },
        xaxis: {
          labels: {
            show: false,
          },
        },
        colors: [
          this.service === "agua"
            ? "#1F429B"
            : this.service === "energia"
            ? "#FF914D"
            : "#8C52FF",
        ],
      };
    },
    chartOptionsArea() {
      return {
        chart: {
          type: "line",
          height: 350,
          toolbar: {
            show: true,
            export: {
              png: {
                filename:
                  this.idDeviceString +
                  " - " +
                  moment(this.dateStart).format("DD/MM/YYYY") +
                  " - " +
                  moment(this.dateEnd).format("DD/MM/YYYY"),
              },
              svg: {
                filename:
                  this.idDeviceString +
                  " - " +
                  moment(this.dateStart).format("DD/MM/YYYY") +
                  " - " +
                  moment(this.dateEnd).format("DD/MM/YYYY"),
              },
              csv: {
                filename: "",
              },
            },
          },
        },
        xaxis: {
          labels: {
            show: false,
          },
          categories: [],
        },
        colors: [
          this.service === "agua"
            ? "#1F429B"
            : this.service === "energia"
            ? "#FF914D"
            : "#8C52FF",
        ],
      };
    },
    isAdmin() {
      return this.$cookies.get("isAdmin") === "false" ? false : true;
    },
  },
  watch: {
    typeConsumo: function () {
      this.searchDeviceData();
    },
  },
  methods: {
    generateDayWiseTimeSeries(timestamp, value) {
      var formattedDate = moment.unix(timestamp).format("DD/MM/YYYY HH:mm:ss");
      console.log(timestamp);
      console.log(formattedDate);
      return { x: formattedDate, y: value };
    },
    mostrarDatos(device, status_ctlr, acumuladoMensual, unidad) {
      this.device = device;
      this.status_ctlr = status_ctlr;
      this.acumuladoMensual = acumuladoMensual;
      this.unidad = unidad;
    },
    hasStatusControl(item) {
      return item.status_ctlr;
    },
    hasValue_in_momment(item) {
      if (item.device_data.length === 0) return false;
      return item.device_data[item.device_data.length - 1].value_in_momment;
    },
    saveDateStart() {
      this.$refs.dialogStart.save(this.dateStart);
      this.timeInicio = moment(this.dateStart).valueOf() / 1000;
    },
    saveDateEnd() {
      this.$refs.dialogEnd.save(this.dateEnd);
      this.timeFinal = moment(this.dateEnd).add(1, "days").valueOf() / 1000;
    },
    searchDeviceData() {
      if (this.$refs.form.validate() && this.deviceDataId) {
        const payload = {
          startDate: this.timeInicio,
          endDate: this.timeFinal,
        };
        this.isloading = true;

        BackendApi.post("/deviceData/deviceData/" + this.deviceDataId, payload)
          .then((response) => {
            if (response.data.success) {
              this.isloading = false;
              let valuex = [];
              const dataset = response.data.data;
              let firstvalueConsumo = dataset[0].value;
              let lastvalueConsumo = dataset[dataset.length - 1].value;
              let firstvalueCaudal = dataset[0].caudal;
              let lastvalueCaudal = dataset[dataset.length - 1].caudal;
              this.chartOptions = null;
              this.seriesApex = null;
              this.tendenciaVolumen = lastvalueConsumo - firstvalueConsumo;
              this.tendenciaCaudal = lastvalueCaudal - firstvalueCaudal;

              if (this.typeConsumo == 1) {
                dataset.forEach((element) => {
                  valuex.push(
                    this.generateDayWiseTimeSeries(
                      element.day,
                      element.value.toFixed(3)
                    )
                  );
                });

                this.chartOptions = this.chartOptionsArea;
                this.seriesApex = [
                  {
                    name: "Consumo (" + this.unidad + ")",
                    data: valuex,
                  },
                ];
                this.$emit("tendencia", this.tendenciaVolumen);
              } else if (this.typeConsumo == 2) {
                dataset.forEach((element) => {
                  valuex.push(
                    this.generateDayWiseTimeSeries(
                      element.day,
                      element.caudal.toFixed(3)
                    )
                  );
                });
                this.chartOptions = this.chartOptionsBar;
                this.seriesApex = [
                  {
                    name: "Caudal(" + this.unidad + "/h)",
                    data: valuex,
                  },
                ];
                this.$emit("tendencia", this.tendenciaCaudal);
              }

              this.isloading = false;
            }
          })
          .catch(() => {
            this.isloading = false;
          });
      }
    },
    async downloadPdf() {
      this.isLoadingDownloadPdf = true;

      const options = {
        scale: 3,
      };
      /* eslint-disable */
      var logo;
      var detail2;
      await html2canvas(document.getElementById("logo-teca")).then((canvas) => {
        logo = canvas.toDataURL("image/png");
      });

      await html2canvas(document.getElementById("infoDevice2")).then(
        (canvas) => {
          detail2 = canvas.toDataURL("image/png");
        }
      );

      await html2canvas(document.getElementById("infoDevice1"), options)
        .then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const doc = new jspdf("p", "pt", "a4");
          const bufferX = 10;
          const bufferY = 260;
          const sizeHead = 16;

          doc.addImage(logo, "JPEG", 189, 10, 200, 100, undefined, "FAST");

          doc.setFontSize(sizeHead);
          const optionsHeader = {
            align: "center",
            font: "helvetica",
          };
          doc.setFontSize(11);
          // doc.text(
          //   "RUC: " + this.$cookies.get("user").ruc,
          //   285,
          //   90,
          //   optionsHeader
          // );
          doc.setFontSize(18);
          doc.text("Reportes Unitario", 290, 130, optionsHeader);
          doc.setFontSize(11);
          doc.text("Generado por: " + this.$cookies.get("user").name, 25, 160);
          doc.setFontSize(11);
          doc.text(
            "Fecha: " + moment(new Date()).format("DD/MM/YYYY"),
            25,
            180
          );
          doc.text(
            "Contacto:" +
              this.$cookies.get("user").phone +
              " mail: " +
              this.$cookies.get("user").email,
            25,
            200
          );
          doc.text(
            "Período: " +
              moment(this.dateStart).format("DD/MM/YYYY") +
              " - " +
              moment(this.dateEnd).format("DD/MM/YYYY"),
            25,
            220
          );
          const itemValue = this.items.find(item => item.id === 2).value;
          const isCaudal = itemValue.includes("Caudal");
          console.log(isCaudal);

          const tipo = isCaudal ? "Caudal" : "Perfil";
          const unidad = isCaudal ? "m3/h" : "kW-h";
          const promedioValue = this.tendenciaCaudal.toFixed(3);
          const showCaudal  = `${tipo} Promedio: ${promedioValue} ${unidad}`
          console.log(showCaudal)
          doc.text(showCaudal, 25, 240);
          doc.text(
            "Consumo: " + this.tendenciaVolumen.toFixed(3) + " " + this.unidad,
            25,
            260
          );
          doc.text("ID usuario: " + this.device.user.id_usuario, 25, 300);
          doc.text("Dni: " + this.device.user.dni, 25, 320);
          doc.text("Nombre: " + this.device.user.name, 25, 340);
          doc.text("Direccion: " + this.device.user.adress, 25, 360);
          doc.text("Suministros: " + this.device.suministro, 25, 380);
          doc.text("Comentarios: " + this.device.commit, 25, 400);
          doc.text("IMP/M3: " + this.device.coefficient, 300, 300);
          doc.text(
            this.device.device_product.telecontrol === true &&
              this.device.device_product.telemetry === true
              ? "TIPO: Telemetria y Telecontrol"
              : this.device.device_product.telecontrol === false &&
                this.device.device_product.telemetry === true
              ? "TIPO: Telemetria"
              : this.device.device_product.telecontrol === true &&
                this.device.device_product.telemetry === false
              ? "TIPO: Telecontrol"
              : "N/A",
            300,
            320
          );
          doc.text(
            this.status_ctlr === true
              ? "Actuador:  Abierto"
              : "Actuador: Cerrado",
            300,
            340
          );
          doc.text("Mensual Acumulado: " + this.acumuladoMensual, 300, 360);
          doc.save(
            `Reporte Unitario ${moment(this.dateStart).format(
              "DD/MM/YYYY"
            )} - ${moment(this.dateEnd).format("DD/MM/YYYY")}.pdf`
          );
          this.isLoadingDownloadPdf = false;
        })
        .catch(() => {
          this.isLoadingDownloadPdf = false;
        });
    },
    async downloadExcel() {
      try {
        this.isLoadingDownload = true;
        const payload = {
          startDate: this.timeInicio,
          endDate: this.timeFinal,
        };
        BackendApi.post(
          "/deviceData/DeviceDataExcel/" + this.deviceDataId,
          payload
        )
          .then((response) => {
            if (response.data.success) {
              const data = [
                {
                  sheet: "Reporte Unitario",
                  columns: [
                    {
                      label: "Fecha",
                      value: (row) => {
                        return typeof row.timestamp === "string"
                        ? moment.unix(parseInt(row.timestamp, 10))
                            .subtract(5, "hours") // Restar 5 horas
                            .tz('UTC')
                            .locale('en')
                            .format("DD/MM/YYYY HH:mm:ss")
                        : "N/A";
                      },
                    },
                    {
                      label: `Pulsos`,
                      value: (row) =>
                        row.pulsos ? row.pulsos : "0",
                    },
                    {
                      label: `Consumo en ${this.unidad}`,
                      value: (row) =>
                        row.value_in_momment ? row.value_in_momment : "0",
                    },
                    {
                      label: this.items.find(item => item.id === 2).value,
                      value: (row) =>
                        row.caudal ? row.caudal : "0",
                    },
                  ],
                  content: [...response.data.data],
                },
              ];
              const hasTelecontrol = response.data.data.some(
                (item) => item.device.device_product.telecontrol !== false
              );
              console.log(hasTelecontrol)
              if (hasTelecontrol) {
                data[0].columns.push({
                  label: "Actuador",
                  value: (row) =>
                    this.hasStatusControl(row) ? "Abierto" : "Cerrado",
                });
              }
              const settings = {
                sheetName: "Data",
                fileName: "Reporte Unitario",
              };

              xlsx(data, settings);
              this.isLoadingDownload = false;
            }
          })
          .catch(() => {});
      } catch (error) {
        this.isLoadingDownload = false;
      }
    },
  },
};
</script>
<style scoped>
::v-deep .apexcharts-menu-item.exportCSV {
  display: none;
}
</style>
