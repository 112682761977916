const toSvg = {
  toParseSvg(msg) {
    const L1 = Math.floor(msg.caConsumo % 10);
    const L2 = Math.floor((msg.caConsumo % 100) / 10);
    const L3 = Math.floor((msg.caConsumo % 1000) / 100);
    const L4 = Math.floor((msg.caConsumo % 10000) / 1000);
    const L5 = Math.floor((msg.caConsumo % 100000) / 10000);
    const L6 = Math.floor((msg.caConsumo % 1000000) / 100000);
    const L7 = Math.floor((msg.caConsumo % 10000000) / 1000000);
    const L8 = Math.floor((msg.caConsumo % 100000000) / 10000000);

    // const txtdevice = msg.device;
    // const txtAlias = msg.alias;
    // const P_Vbat = msg.bateria;

    if (msg.fraude == true) {
      msg.sFraude = "inline";
    } else {
      msg.sFraude = "none";
    }

    let status_bat_L5 = 0;
    let status_bat_L4 = 0;
    let status_bat_L3 = 0;
    let status_bat_L2 = 0;
    let status_bat_L1 = 0;

    let checkConnect2 = "";

    if (0 <= msg.bateria && msg.bateria < 120) {
      status_bat_L5 = "none";
      status_bat_L4 = "none";
      status_bat_L3 = "none";
      status_bat_L2 = "none";
      status_bat_L1 = "none";
    }
    if (10 < msg.bateria && msg.bateria <= 20) {
      status_bat_L5 = "none";
      status_bat_L4 = "none";
      status_bat_L3 = "none";
      status_bat_L2 = "none";
      status_bat_L1 = "inline";
    }
    if (20 < msg.bateria && msg.bateria <= 40) {
      status_bat_L5 = "none";
      status_bat_L4 = "none";
      status_bat_L3 = "none";
      status_bat_L2 = "inline";
      status_bat_L1 = "inline";
    }
    if (40 < msg.bateria && msg.bateria <= 60) {
      status_bat_L5 = "none";
      status_bat_L4 = "none";
      status_bat_L3 = "inline";
      status_bat_L2 = "inline";
      status_bat_L1 = "inline";
    }
    if (60 < msg.bateria && msg.bateria <= 80) {
      status_bat_L5 = "none";
      status_bat_L4 = "inline";
      status_bat_L3 = "inline";
      status_bat_L2 = "inline";
      status_bat_L1 = "inline";
    }
    if (80 < msg.bateria && msg.bateria <= 100) {
      status_bat_L5 = "inline";
      status_bat_L4 = "inline";
      status_bat_L3 = "inline";
      status_bat_L2 = "inline";
      status_bat_L1 = "inline";
    }

    const bateria = [
      {
        //Batt
        command: "update_style",
        elementId: "bat_L5",
        attributeName: "display",
        attributeValue: status_bat_L5,
      },
      {
        command: "update_style",
        elementId: "bat_L4",
        attributeName: "display",
        attributeValue: status_bat_L4,
      },
      {
        command: "update_style",
        elementId: "bat_L3",
        attributeName: "display",
        attributeValue: status_bat_L3,
      },
      {
        command: "update_style",
        elementId: "bat_L2",
        attributeName: "display",
        attributeValue: status_bat_L2,
      },
      {
        command: "update_style",
        elementId: "bat_L1",
        attributeName: "display",
        attributeValue: status_bat_L1,
      },
    ];

    const campana = [
      {
        //Batt
        command: "update_style",
        elementId: "sFraude",
        attributeName: "display",
        attributeValue: msg.sFraude,
      },
    ];

    const detalle = [
      {
        command: "update_text",
        elementId: "txt-device",
        textContent: msg.device,
      },
      {
        command: "update_text",
        elementId: "txt-Alias",
        textContent: msg.alias,
      },
      {
        command: "update_text",
        elementId: "txt-Pulsos",
        textContent: msg.pulsos,
      },
      {
        command: "update_text",
        elementId: "txt-UltimaAct",
        textContent: msg.UMedicion,
      },
      {
        command: "update_text",
        elementId: "txt-ubicacion",
        textContent: msg.ubicacion,
      },
    ];

    const consumo = [
      {
        command: "update_text",
        elementId: "L1",
        textContent: L1,
      },
      {
        command: "update_text",
        elementId: "L2",
        textContent: L2,
      },
      {
        command: "update_text",
        elementId: "L3",
        textContent: L3,
      },
      {
        command: "update_text",
        elementId: "L4",
        textContent: L4,
      },
      {
        command: "update_text",
        elementId: "L5",
        textContent: L5,
      },
      {
        command: "update_text",
        elementId: "L6",
        textContent: L6,
      },
      {
        command: "update_text",
        elementId: "L7",
        textContent: L7,
      },
      {
        command: "update_text",
        elementId: "L8",
        textContent: L8,
      },
    ];

    if (msg.checkConnect2) {
      checkConnect2 = "inline";
      
      
    } else {
      
      checkConnect2 = "none";
      
    }

    const check = [
      {
        command: "update_style",
        elementId: "checkConnect",
        attributeName: "display",
        attributeValue: checkConnect2,
      },
    ];

    let KM1;

    if (msg.status_ctlr == true) {
      KM1 = "inline";
    } else {
      KM1 = "none";
    }

    const statusControl = [
      {
        //por defecto abierto
        command: "update_style",
        elementId: "KM1",
        attributeName: "display",
        attributeValue: KM1,
      },
    ];

    return {
      bateria,
      detalle,
      consumo,
      campana,
      check,
      statusControl,
    };
  },
};

export default toSvg;
